.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.side-by-side {
  display: flex;

  justify-content: center;
  align-items: flex-start;
}


.display-more ~ * {

  /* color: white !important; */
  align-self: flex-start;

}

.main-container {
  width: 100% !important;
}

h2 {
  margin-top: 5rem !important;
  /* color: white !important; */
}

.loading-thing {
  flex: 1;
  align-self: center;
}

.container-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-top: 5rem;
}
.side-by-side {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #282c34;
  min-height: 100vh;
}

.main-wrapper {
  background-color: white;
  box-shadow: 1rem 1rem 3rem #777;
  padding: 1rem;
  margin: 1rem;
}
